
.additive-row {
  display: flex;
  align-items: center;
  position: relative;
}

.additive-selection {
  flex: 1;
}

.additive-buttons {
  position: absolute;
  right: 0;
  margin-right: 2.7px;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.additive-buttons.both-buttons {
  transform: translateY(-25%);
}

.additive-buttons.single-button {
  transform: translateY(0%);
}

.add-button,
.remove-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid grey;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  padding: 0;
}

.add-button:hover,
.remove-button:hover {
  background-color: #f0f0f0;
}

@media (max-width: 600px) {
  .additive-buttons {
    position: static;
    transform: none;
    margin-top: 10px;
  }

  .add-button,
  .remove-button {
    margin-left: 0;
  }

  .additive-buttons.both-buttons {
    transform: none;
  }
}
