
.additive-row {
  display: flex;
  align-items: center;
}

.additive-selection {
  flex: 1;
}

