
.additive-row {
  display: flex;
  align-items: center;
}

.additive-selection {
  flex: 1;
}

.sliders-separator {
  /* margin: 1em 0; */
}
